import Fingerprint2 from 'fingerprintjs2';
const axios = require('axios').default;

//dev
export const baseUrl="http://localhost:5000"
const publicBaseUrl=window.location.protocol+"//"+window.location.hostname+":3000"

//prod
//export const baseUrl = "https://hollo-api.chukolo.com" 
//const publicBaseUrl=window.location.protocol+"//"+window.location.hostname



export const callback_url=publicBaseUrl+"/callback"

export const callback_url_register=publicBaseUrl+"/paid"

export const getToken=()=>{
  const token = JSON.parse(localStorage.getItem('hxxrl'));
    if (!token || !token.access_token) {
    window.location.href="/login"
    }else{
      return token
    }
}
export const holloHeader= () => {
  const token = JSON.parse(localStorage.getItem('hxxrl'));
  if (!token || !token.access_token) { 
    return {headers: {'Content-Type': 'application/json' }  }
  }else{return {headers: {'Content-Type': 'application/json',  'authorization': 'Bearer ' + token.access_token  } }
  }
}
export const  getCurrentDate = () => {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(currentDate.getDate()).padStart(2, '0');
  
  const currentDateString = `${year}-${month}-${day}`
  return currentDateString
}

export async function UpdateDeviceChannels(user_id) {
  try {
    const response = await axios.get(baseUrl + "/channels/fetch/" + user_id, holloHeader());
    
    if (response.data) {
      let responseData = response.data;
      if (responseData.success) {

       localStorage.removeItem('channels')
       return prepareChannels(responseData.data);

        }else {
        console.log(responseData.error);
        alert(responseData.error.message);
      }
    } else {
      console.log("Could not fetch channels" + response);
      alert("Could not fetch channels");
    }
  } catch (error) {
    console.error(error);
    alert(error);
  }
}
export async function UpdateDeviceHashtags() {
  try {                             
    const response = await axios.get(baseUrl + "/hashtags/user/", holloHeader());
    
    if (response.data) {
      let responseData = response.data;
      if (responseData.success) {

       localStorage.removeItem('hashtags')
       localStorage.setItem("hashtags", JSON.stringify(responseData.data));
    

        }else {
        console.log(responseData.error);
   
      }
    } else {
      console.log("Could not fetch hashtags" + response);
 
    }
  } catch (error) {
    console.error(error);
    alert(error);
  }
}



 
  function updateChannel(channel) {
    return {
      "_id": channel._id,
      "channel_tag": channel.channel_tag,
      "user_name": channel.platform_user.title,
      "isSelected": !channel.is_offline,
      "icon": channel.channel_tag + ".png"
    };
  }
  
  function updateChannels(channels) {
    return channels.map(updateChannel);
  }
  
  function saveChannelsToLocalStorage(channels) { 
    localStorage.setItem("channels", JSON.stringify(channels));
   }
  
  function prepareChannels(channels) {
    
    const updatedChannels = updateChannels(channels);
    saveChannelsToLocalStorage(updatedChannels);
   return true
  }


export  function convertNumbThousand(number) {
    let str = "";
    if (number < 1000) {
      str = String(number);
    } else {
      str = (number / 1000).toFixed(1) + "k";
    }
    return str;
  }
  const getDeviceID = () => {
    Fingerprint2.get((components) => {
      const values = components.map((component) => component.value);
      return Fingerprint2.x64hash128(values.join(''), 31);
   });
  };
  
  export  const addMetaInfo =()=>{
         const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        
         const userScreenResolution = `${window.screen.width}x${window.screen.height}`;
         const userOperatingSystem = navigator.platform;
        //  ,
      const meta={
        "timezone": timezone,
        "screen_resolution":userScreenResolution,
        "device_id": getDeviceID(),
        "operating_system":userOperatingSystem,
     }
      return meta 
  
    }
  
   export const logPageVisit =async ()=>{
    const token = JSON.parse(localStorage.getItem('hxxrl'));

    let device_tag = localStorage.getItem('0x10');

    let this_user_id=""
    if (token) {
      this_user_id=token.user_id
    }
   if(!device_tag){

    // this should be randomly generated number 

    const new_tag=generateRef()
    localStorage.setItem('0x10',new_tag)
    device_tag=new_tag

   }
   
   const userOperatingSystem = navigator.platform;
   const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        
   const userScreenResolution = `${window.screen.width}x${window.screen.height}`;
    const data={ 
       page_url: window.location.protocol+"//"+window.location.hostname+window.location.pathname, 
       visit_time: new Date().toLocaleTimeString(),
       visit_day: new Date().toDateString(),
       device_tag: device_tag,
       user_id: this_user_id,
       timezone: timezone,
       screen_resolution: userScreenResolution,
       operating_system: userOperatingSystem,
       device_id: getDeviceID(),
      }
    
    
    try {
      const response = await axios.post(baseUrl+"/marketing/visit-log", data, {headers: {'Content-Type': 'application/json'}});
    
      if (response.data) {
        let responseData = response.data;
    
       
      }
    } catch (error) {
      console.error('An error occurred while making the request:', error);
   
      if (error.response) {
        console.error('Response data:', error.response.data);
      } else if (error.request) {
        console.error('Request:', error.request);
      }
      // handle the error
    }
   } 
  
 export  function generateRef(length = 25) 
   {
 
   const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
   let string = '';
   for (let i = 0; i < length; i++) {
       string += characters[Math.floor(Math.random() * characters.length)];
   }
 
   return string;
   }

   export const toggleChannelState =async (id,state)=>{
   try {  
     const url=baseUrl+"/channels/"+state+"/"+id

      const response = await axios.put(url,{},holloHeader());
    
      if (response.data) {
        let responseData = response.success;
         return responseData
       
      }
    } catch (error) {
      console.error('An error occurred while making the request:', error);
    }
   } 
  
   export const toggleHashtagState =async (id,state)=>{
    try {  
      const url=baseUrl+"/hashtags/"+state+"/"+id
 
       const response = await axios.put(url,{},holloHeader());
     
       if (response.data) {
         let responseData = response.success;
          return responseData
        
       }
     } catch (error) {
       console.error('An error occurred while making the request:', error);
     }
    } 
    export const getUserHashtags =async (user_id)=>{
      try { 
        //replace the right url
        const url=baseUrl+"/hashtags/"+user_id
         //replace the right post object
         const response = await axios.get(url,{},holloHeader());
       
         if (response.data) {
           let responseData = response.success;
            return responseData
          
         }
       } catch (error) {
         console.error('An error occurred while making the request:', error);
       }
      } 
   export const FetchPost = async (is_instant_post,page,page_size) => {
    try {
      const token = JSON.parse(localStorage.getItem('hxxrl'));
      if (!token || !token.access_token) {
        throw new Error('No access token found');}     
      const url =`${baseUrl}/posts/user_post/${token.user_id}?is_instant_post=${is_instant_post}&page=${page}&page_size=${page_size}`
  
      const response = await axios.get(url,holloHeader());
      if (response.data) {
        let responseData = response.data;
  
        if (responseData.success) {
          return responseData.data;
        } else {
          console.log(responseData.error.message);
          throw new Error(responseData.error.message);
        }
      }
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };  



  export const FetchFeaturedPost = async (page,page_size) => {
    try {

         
      const url =`${baseUrl}/posts/featured/?page=${page}&page_size=${page_size}`
  
      const response = await axios.get(url,holloHeader());
      if (response.data) {
        let responseData = response.data;
  
        if (responseData.success) {
          return responseData.data;
        } else {
          console.log(responseData.error.message);
          throw new Error(responseData.error.message);
        }
      }
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };  

  export const FetchUserPost = async (user_id,page,page_size) => {
    try {

         
      const url =`${baseUrl}/posts/user/highlight/?user_id=${user_id}&page=${page}&page_size=${page_size}`
  
      const response = await axios.get(url,holloHeader());
      if (response.data) {
        let responseData = response.data;
  
        if (responseData.success) {
          return responseData.data;
        } else {
          console.log(responseData.error.message);
          throw new Error(responseData.error.message);
        }
      }
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };  


 export  function HolloEncrypt(text) {
  if(!text){
    return null
  }
    const shift = 7; 
    let encryptedText = "";
    for (let i = 0; i < text.length; i++) {
        let charCode = text.charCodeAt(i);
        if (/[a-zA-Z]/.test(text[i])) {
            let shifted = charCode + shift;
            if ((text[i] >= 'a' && shifted > 'z'.charCodeAt(0)) || (text[i] >= 'A' && shifted > 'Z'.charCodeAt(0))) {
                shifted -= 26;
            }
            encryptedText += String.fromCharCode(shifted);
        } else {
            encryptedText += text[i];
        }
    }
    return encryptedText;
}

export function HolloDecrypt(encryptedText) {

    const shift = 7; 
    let decryptedText = "";
    if(!encryptedText){
      return null
    }
    for (let i = 0; i < encryptedText.length; i++) {
        let charCode = encryptedText.charCodeAt(i);
        if (/[a-zA-Z]/.test(encryptedText[i])) {
            let shifted = charCode - shift;
            if ((encryptedText[i] >= 'a' && shifted < 'a'.charCodeAt(0)) || (encryptedText[i] >= 'A' && shifted < 'A'.charCodeAt(0))) {
                shifted += 26;
            }
            decryptedText += String.fromCharCode(shifted);
        } else {
            decryptedText += encryptedText[i];
        }
    }
    return decryptedText;
}

export const calculateTimeElapsed = (dateCreated) => {
  // Parse the dateCreated into a Date object
  const createdDate = new Date(dateCreated); // Use the passed-in dateCreated directly

  // Get the current date in local time
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = currentDate - createdDate;

  // Convert the time difference to seconds, minutes, hours, and days
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // Return the appropriate time elapsed string
  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else {
    return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
  }
};

export const parseCurrency = (currencyString) => {
  return parseFloat(currencyString.replace(/[^\d.-]/g, ''));
};

export const formatCurrency = (amount) => {
  return '₦' + amount.toLocaleString('en-NG', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const capitalizeFirstLetter = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};
